@import '@/theme/newVariables';

.outerContainer {
  margin: 0 calc(var(--st-unit-5) * -1);

  @media #{$small-and-down} {
    margin: 0 calc(var(--st-unit-4) * -1);
  }
}

.container {
  position: relative;
}

.blogContent {
  display: flex;
  flex-direction: column;
  row-gap: var(--st-unit-10);
  position: relative;

  @media #{$medium-and-up} {
    flex-direction: row;
    align-items: stretch;
  }
}

.background {
  display: none;
  background-color: $az-blue-8;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: min(60%, calc(var(--bg-height) - var(--st-unit-4)));
  z-index: -1;

  @media #{$medium-and-up} {
    display: block;
  }
}

.legalContainer {
  padding: var(--st-unit-4);

  @media #{$large-and-up} {
    padding: var(--st-unit-8) var(--st-unit-5);
  }
}

.legalContainerWithBackground {
  @media #{$medium-and-up} {
    background: $az-blue-8;
  }
}

.legalText p {
  color: var(--st-color-gray-700) !important;
}
