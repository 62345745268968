@import '@/theme/newVariables.scss';

.headline h2 {
  line-height: 44px !important;

  @media #{$small-and-down} {
    line-height: 38px !important;
  }
}

.circularPage {
  @media #{$medium-only} {
    padding-left: var(--st-unit-26);
    padding-right: var(--st-unit-26);
  }

  @media #{$large-and-up} {
    padding-left: 206px;
    padding-right: 206px;
  }
}
