@import '@/theme/newVariables';
$link-min-height: 45px;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  .imgContainer {
    position: relative;

    picture {
      display: block;
      height: 100%;
      width: 100%;
    }

    .imgBackground {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    img {
      padding: 0 var(--st-unit-4);
      aspect-ratio: 4 / 3;
      object-fit: cover;
    }
  }

  .contentContainer {
    padding: var(--st-unit-4);
    height: 100%;

    @media #{$medium-and-up} {
      padding: var(--st-unit-2);
    }
  }

  .linkButton {
    margin-top: var(--st-unit-4);
    min-height: $link-min-height;
    width: auto;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .modalLinkButton {
    display: flex;
    align-items: center;
    padding: 10px 0;

    div {
      margin: 1px;
    }

    span {
      margin-left: 4px;
    }
  }

  .heading {
    font-weight: 700;
    line-height: var(--st-unit-6);
  }

  .description {
    line-height: var(--st-unit-5);
  }

  .withBackground {
    background: $az-blue-8;
  }

  @media #{$medium-and-up} {
    .imgContainer {
      img {
        padding: var(--st-unit-2);
      }
    }

    .contentContainer {
      padding: var(--st-unit-2);
    }

    &:first-child {
      .imgContainer {
        img {
          padding-left: var(--st-unit-4);
        }
      }

      .contentContainer {
        padding-left: var(--st-unit-4);
      }
    }

    &:last-child {
      .imgContainer {
        img {
          padding-right: var(--st-unit-4);
        }
      }

      .contentContainer {
        padding-right: var(--st-unit-4);
      }
    }
  }

  @media #{$large-and-up} {
    &:first-child {
      .imgContainer {
        img {
          padding-left: var(--st-unit-5);
        }
      }

      .contentContainer {
        padding-left: var(--st-unit-5);
      }
    }

    &:last-child {
      .imgContainer {
        img {
          padding-right: var(--st-unit-5);
        }
      }

      .contentContainer {
        padding-right: var(--st-unit-5);
      }
    }
  }
}
