@import '../../theme/newVariables';

.buttonContainer {
  position: fixed;
  bottom: 20px;
  right: 30px;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 1px #e5e5e5;
  cursor: pointer;
  padding: 5px;
  border-radius: 50px;
  border: 1px solid #e5e5e5;
}
