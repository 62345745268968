@import '@/theme/newVariables';

.card {
  height: 100%;

  .headerText {
    font-size: var(--st-unit-3);

    @media #{$medium-and-up} {
      font-size: var(--st-unit-4);
    }
  }

  .divider {
    margin: var(--st-unit-2) 0;

    @media #{$medium-and-up} {
      margin: var(--st-unit-1) 0 var(--st-unit-4);
    }
  }
}
