@use '@/theme/newVariables' as *;

.image {
  height: $az-spacing-52;

  @media #{$small-and-down} {
    height: var(--st-unit-15);
  }
}

.container {
  gap: $az-spacing-xxs;
}

.background {
  padding: $az-spacing-xs;
  background-color: $az-grey-1;
}
