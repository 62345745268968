@import '@/theme/newVariables';

.seeAllBtnContainer {
  margin-top: var(--st-unit-4);
  margin-left: calc(-1 * var(--st-unit-5));
}

.activityContainer {
  align-self: stretch;
}

.headline {
  @media #{$extra-large-and-up} {
    padding-bottom: var(--st-unit-1);
  }
}
