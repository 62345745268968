@import '@/theme/newVariables.scss';

.vehicleCardContainer {
  display: flex;
  align-items: center;
  background-color: $az-white;
  box-shadow: $az-box-shadow-gray-1;
  padding: $az-spacing-xxs $az-spacing-xs;
  border-left: $az-spacing-5xs solid $az-black;
  flex: 1;
  margin: $az-spacing-4xs;
  justify-content: space-between;

  @media #{$small-and-down} {
    padding: $az-spacing-xs;
  }
}

.vehicleCardDisplayName {
  @media #{$small-and-down} {
    padding-left: $az-spacing-xs;
  }

  @media #{$medium-and-up} {
    padding: $az-spacing-zero $az-spacing-xxs;
  }
}

.vehicleInfoContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media #{$small-and-down} {
    flex-direction: column;
  }
}

.linkText {
  font-size: $font-size-14;
  color: $az-grey-7;
  text-decoration: underline;
  cursor: pointer;

  @media #{$small-and-down} {
    padding-left: $az-spacing-xs;
    margin-top: $az-spacing-4xs;
  }
}

.vehiclesListContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.infoText {
  font-size: $font-size-16;
  font-weight: 500;
  color: $az-black;
  padding-left: $az-spacing-4xs;
  margin-top: $az-spacing-33;
  margin-bottom: $az-spacing-xxxs;
}
