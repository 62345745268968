.tocContainer {
  h2 {
    --st-text-font-family: var(--st-font-family-headline);
    --st-text-weight: var(--st-font-weight-heavy);
    --st-text-size: var(--st-font-size-200);

    @media (min-width: 710px) {
      --st-text-size: var(--st-font-size-225);
    }
  }

  h3 {
    --st-text-font-family: var(--st-font-family-headline);
    --st-text-weight: var(--st-font-weight-heavy);
    --st-text-size: var(--st-font-size-112);

    @media (min-width: 710px) {
      --st-text-size: var(--st-font-size-175);
    }
  }

  p,
  li {
    --st-text-font-family: var(--st-font-family-body);
    --st-text-weight: var(--st-font-weight-regular);
    --st-text-size: var(--st-font-size-087);

    @media (min-width: 710px) {
      --st-text-size: var(--st-font-size-100);
      --st-text-line-height: 1.4;
    }
  }

  ul,
  li {
    list-style-position: inside;
    list-style-type: disc;
  }

  li {
    padding-left: var(--st-unit-6);
  }

  h2,
  h3,
  p,
  li {
    color: var(--st-text-color, var(--st-color-primary));
    font-family: var(--st-text-font-family, var(--st-font-family-body));
    font-size: var(--st-text-size, var(--st-font-size-100));
    font-style: var(--st-text-style, inherit);
    font-weight: var(--st-text-weight, inherit);
    line-height: var(--st-text-line-height, 1.25);
    text-align: var(--st-text-align, inherit);
    -webkit-text-decoration: var(--st-text-decoration, inherit);
    text-decoration: var(--st-text-decoration, inherit);
    text-transform: var(--st-text-case, inherit);
  }

  h2,
  h3,
  p {
    margin-bottom: var(--st-unit-4);
  }
}
