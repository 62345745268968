@import '@/theme/newVariables';
.iframe {
  height: var(--desktop-height);
  min-width: 100%;
  overflow: hidden;
  border: 0;

  @media #{$medium-only} {
    height: var(--tablet-height);
  }

  @media #{$small-and-down} {
    height: var(--mobile-height);
  }
}
