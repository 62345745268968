@import '@/theme/newVariables';

.balanceMessage {
  font-size: var(--st-unit-3);
  font-weight: 700;
  line-height: var(--st-unit-5);

  @media #{$medium-and-up} {
    font-size: var(--st-unit-4);
    line-height: var(--st-unit-6);
  }
}

.redeemedReward {
  color: var(--action-error-red, $az-color-error);
}

.gainedReward {
  color: var(--action-success-green, $az-color-success);
}
